import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/stylus-for-css",
  "date": "2014-02-21",
  "title": "STYLUS FOR CSS",
  "author": "admin",
  "tags": ["development", "css"],
  "featuredImage": "feature.jpg",
  "excerpt": "When your site grows, the CSS can grow along with it, and the maintenance is no longer  trivial. You could break your CSS in to different files and use @import to structure it into one file. But this doesn’t resolve all your architecting and maintenance problems. "
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`That is where  Stylus, a CSS pre-processor which is similar to SASS and LESS comes in to play. You can further simplify your code with `}<a href="http://jade-lang.com/" target="_blank">{`Jade`}</a>{`, but first let's find out how you can set up this Stylus for CSS.`}</p>
    <h2>{`Setup`}</h2>
    <p>{`In Node.js install `}<a parentName="p" {...{
        "href": "http://expressjs.com/"
      }}>{`Express`}</a>{` and have Stylus set up as a middleware layer.We need a custom `}<inlineCode parentName="p">{`compile`}</inlineCode>{` function to use some features I will explain later, which looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function compile(str, path) {
  return stylus(str);
}
`}</code></pre>
    <p>{`Which is then passed to the middleware setup like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`stylus.middleware({
  src: __dirname + '/public',
  compile : compile
}));
`}</code></pre>
    <h2>{`Compress and organize your CSS`}</h2>
    <p>{`For modular CSS files,  `}<inlineCode parentName="p">{`@import`}</inlineCode>{` can be used to encourage the use of smaller, more modular CSS files. However, minimising HTTP requests is the fastest way to gain page load speed and so CSS is often lumped in a huge 1000 loc+ files.
You can keep your code modular and legible without the HTTP overhead with Stylus,which  `}<inlineCode parentName="p">{`@import`}</inlineCode>{` directives are parsed on the server.
By setting `}<inlineCode parentName="p">{`compress : true`}</inlineCode>{` in the Stylus middleware, compress the CSS output or calling `}<inlineCode parentName="p">{`return stylus(str).set('compress', true)`}</inlineCode>{` in the custom compile function.`}</p>
    <h2>{`Replace Sprite with Stylus`}</h2>
    <p>{`Stylus has the ability to inline images referenced in the CSS as data URIs.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function compile(str, path) {
  return stylus(str)
            .define('url', stylus.url({
              paths : [__dirname + '/public'],
              limit : 10000
            }));
}
`}</code></pre>
    <p><inlineCode parentName="p">{`limit`}</inlineCode>{` is the file size threshold (bytes); if the file is bigger than that, then the url is referenced as usual.
Although URI encoding images incurs a slight size overhead, the resulting CSS file can be gzipped.
This technique means you can reference as many images as you want in your stylesheet without worrying about creating extra HTTP requests.`}</p>
    <h2>{`Vendor Prefixes`}</h2>
    <p><a parentName="p" {...{
        "href": "http://visionmedia.github.com/nib/"
      }}>{`Nib`}</a>{` is a library for Stylus, which provides cross-browser mixins.  Are you writing CSS like this?`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`body {
  background: -webkit-gradient(linear, left top, left bottom,
                    color-stop(0, #fff), color-stop(1, #000));
  background: -webkit-linear-gradient(top, #fff 0%, #000 100%);
  background: -moz-linear-gradient(top, #fff 0%, #000 100%);
  background: linear-gradient(top, #fff 0%, #000 100%);
}
`}</code></pre>
    <p>{`What happens if you want to change `}<inlineCode parentName="p">{`#000`}</inlineCode>{` to `}<inlineCode parentName="p">{`#ccc`}</inlineCode>{`? Pretty painstaking. With nib, you can write this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`body
  background linear-gradient(top, white, black)
`}</code></pre>
    <p>{`Nib jumps in and vendor-prefixes everything for you, so you don't have to worry about it. I literally only use it for that purpose, but it does lots of other useful things if you want it to.
To use nib with your custom `}<inlineCode parentName="p">{`compile`}</inlineCode>{` function do this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// Make sure you install it
// with \`npm install nib\`

var nib = require('nib');

function compile(str, path) {
  return stylus(str)
            .use(nib());
}
`}</code></pre>
    <h2>{`Lose the punctuation`}</h2>
    <p>{`One of my favourite features of Stylus is the syntax. You have the option to go completely punctuation-free: no semi-colons, no colons and no curly-braces, just whitespace and indentation.
You could still write regular CSS or you can use the short hand:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`*
  margin 0
  padding 0

* {
  margin: 0;
  padding: 0;
}
`}</code></pre>
    <p>{`In the punctuation-free example, only the useful tokens (excl. whitespace) exist, of which there are five. In the punctuated example, there are six extraneous punctuation tokens. It's up to you to choose.`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`Here is my complete custom `}<inlineCode parentName="p">{`compile`}</inlineCode>{` function:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function compile(str, path) {
  return stylus(str)
    .define('url', stylus.url({
      paths : [__dirname + "/public"],
      limit : 10000
    }))
    .set('filename', path)
    .set('compress', true)
    .use(nib());
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      